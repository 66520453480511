import React, {useContext, useEffect, useRef, useState} from "react"
import tw from "twin.macro"
import lottie from 'lottie-web'
import animation from '../../static/404-not-found.json'
import TranslateClient from "../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../helpers")
import { LanguageContext } from "../context/Language"


const NotFoundPage:React.FC = () => {
  const message = "We're sorry. Looks like the page you are looking for does not exist"
  const animationRef = useRef(null)
  const [text, setText] = useState({message})
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    lottie.loadAnimation({
      container: animationRef.current,
      animationData: animation,
      loop: true
    })
  }, [])

  useEffect(() => {
    if (language === "en") {
      setText({message})
    } else {
      (async () => {
        const textToTranslate = getStringValuesArray([message])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
        
      })()
    }
  }, [language])

  return (
    <div css={[tw`flex flex-col items-center mt-20 gap-8`]}>
      <div css={[tw`h-80`]} ref={animationRef} />
      <h2 css={[tw`font-bold px-12 text-center max-w-2xl mb-8 sm:mb-0`]}>{text?.message}</h2>
    </div>
  )
}

export default NotFoundPage